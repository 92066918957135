import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
					<path d="M5230 7889 c-74 -4 -160 -13 -190 -18 -30 -6 -79 -14 -107 -17 -29
-4 -53 -10 -53 -15 0 -4 -19 -12 -42 -17 -24 -5 -42 -13 -41 -19 2 -7 -11 -9
-36 -6 -25 3 -42 0 -52 -9 -8 -8 -41 -22 -74 -32 -73 -23 -93 -38 -45 -34 24
2 34 -1 32 -9 -1 -7 2 -13 8 -13 5 0 10 -22 10 -50 0 -49 -1 -50 -30 -50 -26
0 -30 -4 -30 -28 0 -16 -8 -34 -20 -42 -14 -10 -16 -16 -7 -22 6 -4 -26 -7
-73 -7 -61 0 -79 3 -65 10 15 7 8 8 -26 5 -31 -2 -59 -12 -81 -30 -35 -26 -68
-34 -68 -17 0 5 6 12 13 14 9 4 9 6 0 6 -7 1 -28 -12 -46 -29 -34 -29 -54 -39
-42 -21 9 16 -10 21 -41 11 l-29 -8 30 -1 c19 -1 26 -4 19 -11 -14 -14 -77
-19 -91 -8 -9 7 -15 5 -19 -6 -3 -8 -25 -25 -49 -37 -24 -12 -48 -32 -53 -44
-6 -13 -53 -55 -104 -93 -128 -95 -252 -230 -358 -387 -18 -27 -37 -52 -40
-55 -10 -8 -103 -188 -137 -265 -55 -125 -75 -186 -113 -348 -68 -288 -67
-696 0 -969 34 -135 42 -161 61 -205 11 -24 19 -48 19 -54 0 -37 189 -377 256
-458 7 -9 34 -45 61 -80 71 -97 231 -251 347 -335 158 -115 376 -235 521 -286
33 -12 69 -25 80 -30 11 -4 40 -13 65 -20 25 -6 59 -15 75 -20 58 -16 161 -38
230 -48 39 -6 100 -16 136 -21 85 -14 276 -14 393 0 206 24 485 88 568 130 10
5 22 9 28 9 17 0 307 139 387 186 30 18 49 42 40 51 -2 2 -16 -1 -31 -7 -36
-13 -475 -13 -568 1 -40 6 -104 15 -143 20 -111 16 -174 28 -260 51 -65 17
-124 23 -315 29 -129 4 -256 12 -282 18 -27 6 -67 15 -90 20 -40 8 -147 42
-193 61 -11 4 -51 20 -90 35 -66 25 -135 60 -265 133 -134 76 -321 256 -405
392 -74 121 -175 354 -175 407 0 12 -4 24 -9 27 -10 6 -24 105 -42 285 -25
254 33 620 132 839 16 34 29 66 29 71 0 21 78 163 145 266 149 227 329 405
559 552 67 43 340 176 391 191 22 7 69 21 105 33 114 37 238 37 493 -1 37 -5
96 -14 132 -19 62 -9 149 -33 187 -52 10 -5 27 -9 38 -9 11 0 28 -4 38 -9 9
-6 64 -31 122 -57 193 -87 397 -236 541 -396 41 -45 88 -98 106 -117 l32 -35
93 92 c51 50 104 100 117 109 13 10 33 28 44 41 11 12 28 22 36 22 9 1 41 16
71 34 85 50 82 65 -34 178 -152 149 -305 271 -446 354 -74 44 -245 130 -305
153 -57 23 -128 45 -195 62 -41 11 -84 24 -95 28 -11 5 -56 14 -100 21 -44 6
-98 15 -120 20 -58 13 -309 18 -465 9z m-353 -95 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-84 -31 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0
19 -2 13 -5z m-446 -279 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M4540 7700 c0 -16 50 -24 63 -10 9 10 7 11 -10 6 -12 -4 -24 -2 -28
4 -8 13 -25 13 -25 0z"/>
<path d="M4458 7683 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4415 7650 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M4360 7635 c-20 -24 -6 -28 15 -5 10 11 13 20 8 20 -6 0 -16 -7 -23
-15z"/>
<path d="M4460 7621 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M4390 7608 c0 -5 11 -8 25 -8 14 0 25 3 25 8 0 4 -11 7 -25 7 -14 0
-25 -3 -25 -7z"/>
<path d="M4563 7605 c0 -12 3 -12 16 -3 9 7 18 14 20 15 2 2 -5 3 -16 3 -11 0
-20 -7 -20 -15z"/>
<path d="M4300 7600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4265 7570 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M5265 7390 c-33 -4 -91 -11 -130 -14 -92 -9 -243 -52 -342 -97 -24
-10 -47 -19 -52 -19 -15 0 -142 -70 -197 -109 -119 -84 -214 -165 -296 -253
-95 -101 -148 -166 -148 -180 0 -5 -12 -21 -26 -36 -14 -15 -35 -45 -46 -67
-11 -22 -24 -42 -28 -45 -6 -5 -19 -31 -79 -161 -12 -25 -21 -48 -21 -52 0 -4
-9 -26 -19 -50 -21 -45 -43 -112 -46 -137 -1 -8 -5 -22 -7 -30 -31 -87 -29
-523 2 -581 6 -10 10 -27 10 -38 0 -11 9 -35 20 -53 13 -22 16 -34 8 -39 -7
-5 5 -40 36 -105 65 -137 83 -169 98 -169 7 0 14 -8 16 -19 3 -13 0 -16 -9
-10 -18 11 52 -77 79 -99 12 -10 22 -23 22 -28 0 -23 195 -179 224 -179 9 0
16 -4 16 -10 0 -5 5 -10 10 -10 6 0 27 -10 48 -23 58 -37 228 -106 275 -113
23 -3 51 -9 62 -14 51 -22 501 -36 575 -19 51 13 53 14 25 20 -71 17 -150 40
-174 53 -14 7 -63 31 -108 52 -46 21 -83 41 -83 45 0 4 -15 13 -34 19 -19 6
-40 18 -46 26 -7 7 -21 14 -31 14 -11 0 -19 6 -19 14 0 12 -33 30 -52 27 -4 0
-10 8 -13 19 -4 11 -10 20 -14 20 -10 0 -161 148 -186 183 -11 15 -26 34 -32
42 -7 7 -13 16 -13 20 0 3 -13 20 -30 38 -16 18 -30 38 -30 45 0 6 -4 12 -10
12 -5 0 -10 6 -10 13 0 7 -6 22 -14 32 -12 16 -16 17 -30 6 -14 -12 -16 -10
-16 20 0 25 3 30 10 19 15 -25 20 -6 7 30 -7 19 -15 40 -20 47 -8 13 -70 200
-85 258 -25 98 -32 174 -32 351 0 193 10 270 55 434 37 131 141 329 214 405
26 28 61 66 77 86 67 84 224 200 354 262 140 67 200 85 375 113 117 19 132 24
114 35 -16 10 -116 9 -204 -1z m-1018 -992 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m-34 -35 c4 -19 2 -33 -3 -33 -6 0 -10 17 -10 37 0 43 5 41
13 -4z m-33 -99 c0 -20 27 -48 32 -33 2 6 3 -8 3 -30 l0 -41 -67 0 -68 0 0 40
0 40 40 0 c33 0 40 3 40 20 0 11 5 20 10 20 6 0 10 -7 10 -16z m10 -324 c6 9
11 6 20 -10 10 -18 9 -21 -1 -15 -9 6 -11 2 -7 -13 4 -12 2 -24 -3 -27 -14 -9
2 -35 22 -35 13 0 19 -7 19 -24 0 -14 4 -27 9 -30 5 -3 8 -14 6 -24 -14 -72
-15 -84 -1 -79 11 4 12 -2 6 -29 -8 -35 5 -59 16 -30 7 18 24 11 24 -10 0 -9
-10 -14 -29 -14 -28 0 -30 3 -33 42 l-3 41 -77 -2 -78 -2 0 40 0 41 40 0 40 0
0 40 c0 22 4 40 9 40 5 0 5 15 1 35 -8 35 -1 93 8 66 3 -10 6 -11 12 -1z m67
-137 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m11 -240 c-7 -9
-7 -17 1 -27 9 -11 6 -12 -14 -8 -29 5 -32 17 -8 26 15 6 15 8 3 16 -11 7 -10
9 7 7 17 -1 19 -4 11 -14z m-251 -63 c3 -11 9 -20 14 -20 5 0 9 9 9 20 0 11 5
20 10 20 6 0 10 -13 10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -4 -10 -10 0 -6 27
-10 60 -10 l61 0 -3 -77 -3 -78 -37 -3 c-31 -3 -38 -7 -38 -24 0 -12 3 -19 6
-15 3 3 18 1 33 -4 24 -9 25 -8 7 6 -11 8 -15 15 -8 15 6 0 17 -6 24 -14 11
-11 14 -11 23 0 8 11 10 9 11 -11 1 -16 -5 -26 -17 -30 -10 -4 -19 -15 -19
-26 0 -14 -6 -18 -24 -17 -14 1 -23 5 -21 8 2 3 0 12 -6 20 -7 12 -9 11 -9 -4
0 -19 0 -19 -14 -1 -9 13 -26 19 -50 19 -42 0 -45 3 -11 16 17 6 23 14 18 26
-5 12 -7 13 -14 2 -5 -7 -9 -8 -9 -3 0 6 4 16 9 24 10 15 -3 46 -29 69 -8 7
-10 11 -5 9 6 -3 10 25 10 76 -1 45 1 85 3 90 7 14 24 7 29 -13z m160 -97 c-2
-21 -4 -4 -4 37 0 41 2 58 4 38 2 -21 2 -55 0 -75z m185 35 c-7 -7 -10 -5 -7
8 5 29 6 30 12 16 3 -8 1 -18 -5 -24z m-451 -31 c-8 -8 -11 -7 -11 4 0 20 13
34 18 19 3 -7 -1 -17 -7 -23z m433 -9 c-12 -20 -12 -49 0 -45 6 2 9 12 8 22
-3 17 -2 17 13 5 11 -9 13 -16 6 -18 -6 -2 -11 -10 -11 -18 0 -14 -29 -20 -31
-6 0 4 -2 16 -4 27 -2 10 1 25 7 32 13 17 22 17 12 1z m-44 -97 c0 -5 -7 -14
-15 -21 -16 -14 -18 -10 -9 14 6 17 24 22 24 7z m-322 -50 c3 -8 -1 -12 -9 -9
-7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m318 4 c-1 -8 -5 -17
-8 -21 -5 -4 -4 16 1 34 3 9 9 -1 7 -13z m-84 -32 c-6 -3 -9 -10 -6 -15 4 -7
2 -8 -5 -4 -9 6 -9 11 0 22 6 8 15 12 18 8 3 -4 0 -9 -7 -11z m281 -18 c4 -8
2 -17 -3 -20 -6 -4 -10 3 -10 14 0 25 6 27 13 6z m44 -52 c19 -15 8 -33 -18
-33 -14 0 -18 4 -12 13 4 6 7 23 5 37 -1 14 1 18 4 10 4 -8 13 -20 21 -27z
m-42 -3 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-430 -40 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4
-10z m612 -126 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m-517 6
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m240
-160 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M4136 5332 c-2 -4 -1 -14 5 -22 7 -12 9 -10 9 8 0 23 -5 28 -14 14z"/>
<path d="M4737 6863 c-4 -3 -7 -382 -7 -842 l0 -836 23 -39 c37 -66 67 -81
157 -81 49 0 84 5 91 12 10 10 12 127 10 509 -2 406 0 495 11 492 7 -3 18 -13
23 -24 6 -10 28 -50 48 -89 80 -149 149 -271 189 -336 115 -181 113 -180 193
-30 22 42 58 109 80 149 120 219 146 269 161 309 9 24 21 43 25 43 5 0 9 -225
9 -499 0 -563 -5 -531 77 -531 76 0 166 58 203 131 19 37 20 62 20 838 0 922
14 821 -115 824 -74 2 -75 2 -104 -33 -16 -19 -38 -52 -48 -72 -10 -21 -21
-38 -25 -38 -5 0 -8 -5 -8 -12 0 -6 -38 -75 -84 -152 -46 -78 -100 -170 -120
-206 -20 -36 -48 -83 -62 -105 -13 -22 -35 -60 -48 -85 -34 -63 -49 -81 -62
-72 -7 4 -55 79 -107 167 -53 88 -124 207 -159 264 -35 56 -79 131 -98 165
-72 127 -104 175 -124 180 -29 8 -141 7 -149 -1z"/>
<path d="M6354 6722 c-6 -4 -13 -14 -16 -22 -2 -8 -15 -15 -29 -15 -21 0 -24
-4 -23 -32 2 -36 -10 -42 -36 -18 -14 13 -19 13 -35 1 -17 -12 -18 -17 -7 -41
7 -14 10 -30 7 -35 -3 -5 -1 -28 4 -52 8 -33 6 -50 -5 -75 -12 -25 -14 -40 -6
-65 18 -57 24 -690 9 -818 l-8 -65 19 60 c32 99 42 152 42 228 0 88 14 119 24
51 16 -122 -11 -327 -59 -439 -110 -260 -183 -368 -315 -468 -30 -23 -57 -45
-60 -48 -3 -3 -50 -37 -105 -74 -111 -77 -275 -165 -306 -165 -10 0 -41 -11
-67 -25 -48 -25 -112 -45 -227 -71 -72 -16 -177 -18 -264 -4 -119 20 -96 -4
102 -105 81 -41 152 -75 157 -75 6 0 29 -9 53 -19 23 -10 69 -26 102 -35 33
-8 87 -23 120 -32 33 -9 80 -20 105 -24 25 -5 74 -14 110 -20 133 -24 178 -30
295 -39 66 -6 158 -15 205 -20 134 -17 402 13 550 61 67 22 189 68 250 96 114
51 298 211 377 328 102 150 209 354 249 474 8 25 19 54 23 65 18 45 31 93 31
116 l-1 24 -14 -25 c-8 -14 -22 -47 -31 -75 -26 -79 -82 -174 -142 -242 -84
-97 -88 -83 -10 38 44 67 138 252 138 271 0 7 9 38 21 68 12 30 30 89 40 130
30 118 22 190 -9 85 -96 -320 -152 -433 -313 -630 -65 -80 -149 -164 -149
-150 0 6 14 23 30 38 29 27 39 48 23 46 -5 0 -26 -21 -47 -46 -33 -37 -37 -46
-25 -55 11 -8 11 -9 -4 -4 -17 5 -50 -19 -128 -96 -21 -21 -41 -38 -45 -38 -3
0 -20 -10 -37 -22 -71 -51 -189 -109 -271 -134 -28 -9 -74 -27 -103 -40 -28
-13 -66 -24 -82 -24 -17 0 -31 -4 -31 -10 0 -5 -12 -10 -27 -9 l-28 1 28 11
c15 6 27 16 27 23 0 13 75 44 110 44 42 0 228 92 309 154 57 43 73 66 47 66
-26 0 -17 24 16 45 17 11 34 27 36 35 7 19 -28 -4 -123 -79 -104 -84 -207
-143 -312 -182 -32 -12 -62 -25 -68 -30 -5 -5 -19 -9 -32 -9 -12 0 -32 -4 -45
-9 -83 -33 -221 -62 -388 -81 -41 -5 -93 -11 -115 -14 -22 -3 -112 -10 -201
-17 -122 -9 -166 -9 -182 0 -33 18 -27 29 21 36 23 3 53 10 67 14 14 5 50 14
80 21 144 32 297 92 414 162 44 27 83 48 88 48 4 0 8 4 8 8 0 4 14 16 32 27
31 18 162 138 219 200 61 67 179 235 198 283 7 17 16 32 20 32 3 0 7 11 7 24
2 22 40 87 57 94 4 2 7 12 7 23 0 10 4 19 8 19 13 0 38 67 51 133 26 138 31
166 35 241 5 83 -11 96 -21 17 -3 -22 -10 -41 -15 -41 -6 0 -8 -6 -5 -12 2 -7
1 -22 -4 -33 -11 -28 -11 51 0 138 6 46 5 86 -3 127 -7 33 -9 65 -4 74 11 19
20 115 12 128 -3 5 -11 2 -18 -7 -11 -15 -13 -14 -23 7 -20 42 -34 140 -22
155 6 7 8 13 4 13 -4 0 -15 -10 -26 -22 -16 -20 -18 -20 -19 -6 0 10 -9 23
-20 30 -11 7 -20 19 -20 27 0 13 -36 89 -75 160 l-14 25 -7 -28 c-6 -27 -7
-27 -25 -7 -11 11 -19 23 -19 26 0 4 -12 31 -26 61 -14 31 -23 58 -20 61 8 8
38 -24 53 -57 6 -14 12 -19 12 -12 1 17 21 15 21 -3 0 -8 9 -20 20 -27 11 -7
20 -18 20 -24 0 -6 16 -31 35 -55 19 -24 35 -49 35 -55 0 -6 7 -17 15 -24 12
-10 15 -10 15 0 0 54 -145 254 -248 343 -36 30 -33 29 -48 19z m1 -52 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m42 -12 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-61 -80 c36 -42 107 -163 106
-180 0 -9 6 -22 14 -29 15 -12 20 -49 6 -49 -9 0 -42 74 -42 95 0 8 -4 15 -9
15 -5 0 -13 10 -17 23 -4 12 -10 24 -14 27 -4 3 -24 31 -44 63 -37 57 -37 77
0 35z m224 -218 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11
-4 11 -10z m30 -80 c6 0 10 -14 10 -31 0 -17 -4 -28 -10 -24 -5 3 -10 17 -10
31 0 13 -4 24 -10 24 -5 0 -10 15 -9 33 l1 32 9 -32 c5 -18 14 -33 19 -33z
m-100 -36 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10
-16z m59 -56 c0 -31 -1 -31 -10 -10 -5 13 -7 28 -3 33 10 16 14 9 13 -23z
m-249 -18 c0 -13 -1 -13 -10 0 -5 8 -10 22 -10 30 0 13 1 13 10 0 5 -8 10 -22
10 -30z m267 -42 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m72
-50 c-1 -45 -2 -49 -9 -23 -11 44 -11 75 0 75 6 0 9 -23 9 -52z m70 -153 c0
-55 -4 -116 -9 -135 -5 -24 -8 12 -9 115 0 99 3 145 9 135 5 -8 9 -60 9 -115z
m-162 38 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-185 -45 c0
-35 1 -70 0 -78 0 -8 3 -33 8 -55 10 -47 -9 -206 -25 -205 -5 0 -10 23 -10 50
2 258 6 350 15 350 6 0 11 -28 12 -62z m295 -45 c-3 -10 -5 -2 -5 17 0 19 2
27 5 18 2 -10 2 -26 0 -35z"/>
<path d="M6715 6570 c-9 -14 10 -36 36 -43 25 -7 36 17 19 38 -14 17 -46 20
-55 5z"/>
<path d="M7035 6572 c-13 -9 -12 -49 2 -63 8 -8 16 -8 32 0 26 14 27 39 4 57
-21 16 -25 16 -38 6z"/>
<path d="M7457 6450 c-9 -11 -17 -23 -17 -27 0 -9 33 -32 47 -33 21 0 35 37
23 60 -14 25 -31 25 -53 0z"/>
<path d="M6863 6385 c-17 -19 -16 -28 2 -21 9 3 15 0 15 -9 0 -17 25 -20 35
-5 7 11 -16 50 -30 50 -5 -1 -16 -7 -22 -15z"/>
<path d="M6640 6311 c0 -17 18 -21 24 -6 3 9 0 15 -9 15 -8 0 -15 -4 -15 -9z"/>
<path d="M7282 6279 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M6770 6025 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"/>
<path d="M7220 6042 c0 -5 7 -17 15 -28 14 -18 14 -18 15 9 0 17 -5 27 -15 27
-8 0 -15 -4 -15 -8z"/>
<path d="M7036 5997 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M7233 5837 c-18 -39 -16 -55 3 -35 9 8 14 26 12 39 -3 23 -3 23 -15
-4z"/>
<path d="M6915 5361 c3 -5 1 -12 -5 -16 -5 -3 -10 -10 -10 -15 0 -6 9 -2 20 7
24 21 26 33 4 33 -8 0 -13 -4 -9 -9z"/>
<path d="M7430 5235 c0 -8 -4 -15 -10 -15 -5 0 -10 -9 -10 -20 0 -11 -4 -20
-10 -20 -12 0 -41 -55 -33 -64 7 -7 19 10 57 77 29 53 30 57 16 57 -5 0 -10
-7 -10 -15z"/>
<path d="M7236 5215 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M7338 5076 c-40 -48 -58 -75 -58 -84 0 -6 -4 -12 -8 -14 -10 -4 -62
-73 -62 -81 0 -4 5 -7 10 -7 6 0 10 5 10 10 0 6 7 18 16 28 40 45 116 152 112
160 -1 5 -11 0 -20 -12z"/>
<path d="M7241 5074 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7145 5050 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3900 4963 c0 -7 5 -13 10 -13 6 0 10 -7 10 -16 0 -16 41 -79 85
-129 15 -16 45 -52 68 -79 23 -27 51 -57 61 -65 10 -9 42 -36 70 -61 78 -69
142 -120 150 -120 5 0 20 -8 34 -18 142 -99 377 -178 617 -207 53 -6 58 -5 46
9 -7 9 -19 16 -26 16 -15 0 -138 81 -254 168 -105 78 -138 96 -235 122 -42 11
-84 24 -94 30 -9 5 -50 21 -90 35 -71 26 -210 111 -281 171 -19 16 -65 61
-103 99 -37 39 -68 64 -68 58z"/>
<path d="M7159 4953 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M7081 4857 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M6667 4753 c-25 -17 -34 -30 -28 -36 7 -7 19 -1 37 16 15 15 31 27
35 27 5 0 9 5 9 10 0 16 -13 12 -53 -17z"/>
<path d="M6435 4690 c-27 -28 -44 -50 -37 -50 14 0 102 80 102 93 0 16 -17 4
-65 -43z"/>
<path d="M6930 4725 c-7 -9 -10 -18 -7 -22 8 -7 37 15 37 28 0 14 -16 11 -30
-6z"/>
<path d="M6594 4709 c-11 -18 -172 -89 -197 -87 -13 1 -46 -15 -77 -38 -30
-23 -76 -52 -102 -66 -60 -32 -52 -42 15 -18 103 37 154 59 262 114 125 64
150 80 140 95 -8 14 -32 14 -41 0z"/>
<path d="M4150 3004 c-59 -21 -86 -47 -121 -116 -36 -71 -36 -108 -2 -176 48
-96 145 -146 234 -122 52 14 99 45 99 66 0 24 -25 26 -64 5 -51 -29 -103 -27
-154 6 -71 46 -93 133 -53 211 35 68 94 86 235 70 41 -4 43 16 5 43 -41 29
-118 35 -179 13z"/>
<path d="M6324 3010 c-54 -12 -92 -46 -99 -88 -11 -69 24 -117 101 -139 22 -6
59 -17 83 -24 29 -9 47 -21 54 -37 10 -22 8 -28 -23 -53 -42 -35 -96 -38 -163
-10 -25 11 -48 17 -51 14 -14 -14 -4 -42 21 -59 34 -22 164 -25 203 -5 74 39
107 108 76 156 -22 33 -57 50 -139 66 -50 9 -74 19 -87 36 -23 30 -15 62 19
74 25 10 117 13 171 7 19 -2 25 2 25 17 0 42 -102 67 -191 45z"/>
<path d="M3114 2997 c-2 -7 -3 -98 -2 -202 3 -177 4 -190 22 -193 29 -6 36 16
36 118 0 152 16 150 74 -10 39 -109 72 -107 118 5 15 37 28 74 28 82 0 21 31
64 41 57 5 -3 9 -59 9 -125 0 -99 3 -121 16 -126 32 -12 39 15 42 175 2 86 0
173 -5 192 -8 30 -14 35 -41 38 -36 4 -33 9 -76 -128 -15 -47 -31 -94 -36
-105 -4 -11 -11 -28 -14 -38 -11 -36 -31 -10 -61 79 -17 49 -33 94 -36 99 -4
6 -9 22 -13 37 -3 15 -13 34 -22 42 -19 19 -73 21 -80 3z"/>
<path d="M3733 2998 c-5 -7 -15 -29 -23 -48 -7 -19 -20 -53 -30 -75 -10 -22
-30 -71 -44 -110 -15 -38 -35 -89 -46 -111 -10 -23 -16 -45 -13 -48 14 -14 52
-5 58 13 3 10 17 31 32 45 23 23 33 26 98 26 l73 0 27 -46 c20 -35 32 -44 48
-42 36 5 30 30 -85 326 -12 30 -27 60 -35 68 -18 17 -47 18 -60 2z m47 -118
c4 -8 12 -26 18 -40 5 -14 15 -38 21 -53 6 -15 11 -30 11 -32 0 -3 -28 -5 -61
-5 l-61 0 7 38 c18 101 44 136 65 92z"/>
<path d="M4480 2990 c-7 -12 -9 -88 -8 -202 3 -178 4 -183 24 -186 26 -4 34
19 34 95 0 67 13 75 128 71 l87 -3 5 -80 c5 -77 6 -80 30 -80 l25 0 0 200 0
200 -25 0 c-24 0 -25 -2 -28 -80 -2 -51 -7 -84 -16 -90 -7 -6 -51 -8 -107 -5
l-94 5 -3 65 c-3 85 -9 110 -27 110 -8 0 -19 -9 -25 -20z"/>
<path d="M4926 2994 c-9 -24 -6 -368 4 -383 4 -7 16 -11 26 -9 18 3 19 15 19
203 0 196 0 200 -21 203 -13 2 -24 -4 -28 -14z"/>
<path d="M5105 2998 c-3 -8 -4 -96 -3 -197 3 -164 5 -185 21 -195 27 -17 37 8
37 92 0 39 4 72 8 72 5 0 48 -38 97 -85 71 -66 98 -85 128 -90 21 -4 41 -4 45
0 4 4 -39 51 -95 104 -57 53 -103 102 -103 107 0 5 38 47 85 94 47 47 85 90
85 97 0 35 -65 -4 -157 -91 -32 -31 -64 -56 -70 -56 -9 0 -13 18 -14 53 -1 74
-11 101 -37 105 -12 2 -24 -3 -27 -10z"/>
<path d="M5652 2998 c-7 -7 -12 -16 -12 -20 0 -5 -9 -27 -19 -51 -11 -23 -29
-69 -40 -102 -10 -33 -31 -91 -45 -130 -32 -87 -32 -95 -1 -95 17 0 29 8 35
23 22 52 38 62 98 61 84 -1 106 -9 133 -49 26 -39 59 -47 59 -15 0 11 -11 48
-24 82 -14 35 -34 90 -46 123 -12 33 -30 78 -40 100 -9 22 -21 50 -24 63 -8
25 -53 31 -74 10z m62 -130 c9 -29 22 -63 28 -75 18 -36 4 -53 -44 -53 -68 0
-74 8 -53 76 16 54 40 104 49 104 2 0 11 -24 20 -52z"/>
<path d="M6947 3004 c-4 -4 -7 -57 -8 -118 -1 -166 -7 -196 -48 -223 -51 -35
-101 -31 -145 11 l-36 33 0 131 c0 72 -3 137 -6 146 -3 9 -17 16 -29 16 -21 0
-25 -6 -31 -47 -9 -60 1 -239 16 -278 6 -16 25 -41 43 -55 27 -22 41 -25 107
-25 64 0 82 4 111 24 71 48 74 59 74 231 0 148 -1 155 -20 158 -12 2 -24 0
-28 -4z"/>
<path d="M7127 3003 c-4 -3 -7 -93 -7 -199 0 -232 -9 -219 146 -211 125 6 175
28 192 85 10 31 8 41 -15 83 -24 46 -25 50 -9 73 20 32 21 95 0 124 -27 39
-77 52 -194 52 -58 0 -110 -3 -113 -7z m237 -75 c26 -27 25 -58 -2 -84 -24
-22 -34 -24 -97 -22 l-70 3 -3 41 c-4 60 14 74 94 74 39 0 70 -5 78 -12z m14
-184 c13 -4 16 -52 4 -70 -15 -23 -53 -34 -121 -34 l-71 0 0 50 c0 43 3 52 23
60 20 8 133 4 165 -6z"/>
<path d="M3692 2318 c2 -78 7 -113 16 -116 9 -3 12 24 12 112 0 98 -2 116 -15
116 -13 0 -15 -17 -13 -112z"/>
<path d="M3375 2411 c-3 -5 9 -11 27 -13 l33 -3 3 -98 c2 -72 6 -97 15 -94 8
2 13 36 15 100 l3 97 29 0 c17 0 30 5 30 10 0 13 -147 13 -155 1z"/>
<path d="M5090 2405 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M5570 2405 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M5480 2380 c0 -12 -7 -20 -17 -20 -17 -1 -17 -1 0 -14 12 -9 17 -27
17 -64 0 -48 17 -82 40 -82 17 0 21 20 6 26 -22 9 -23 114 -1 114 8 0 15 5 15
10 0 6 -7 10 -15 10 -8 0 -15 9 -15 20 0 11 -7 20 -15 20 -8 0 -15 -9 -15 -20z"/>
<path d="M3546 2335 c-24 -36 -20 -78 10 -110 21 -23 31 -26 57 -21 17 4 36
11 41 16 16 16 -2 24 -28 12 -18 -8 -28 -7 -45 4 -39 28 -26 44 34 44 30 0 55
2 55 5 0 3 -7 21 -14 40 -14 32 -19 35 -54 35 -31 0 -43 -5 -56 -25z m84 1 c0
-2 3 -11 6 -20 5 -13 -2 -16 -35 -16 -44 0 -44 0 -35 24 5 14 64 24 64 12z"/>
<path d="M3776 2338 c-52 -74 40 -184 99 -118 14 15 15 20 4 20 -8 0 -20 -4
-28 -9 -20 -13 -61 8 -61 31 0 15 8 18 50 18 44 0 50 3 50 21 0 41 -18 59 -60
59 -29 0 -43 -6 -54 -22z m84 -2 c0 -2 3 -11 6 -20 5 -13 -2 -16 -35 -16 -44
0 -44 0 -35 24 5 14 64 24 64 12z"/>
<path d="M3937 2342 c-19 -20 -23 -83 -7 -112 19 -36 100 -31 115 8 9 25 -4
27 -29 3 -20 -19 -28 -21 -45 -12 -46 25 -35 111 14 111 14 0 28 -7 31 -15 4
-8 12 -15 20 -15 15 0 8 30 -10 42 -21 14 -73 8 -89 -10z"/>
<path d="M4086 2338 c-24 -34 -20 -85 9 -113 30 -30 54 -31 89 -4 31 24 36 81
10 117 -21 31 -87 31 -108 0z m88 -14 c13 -35 6 -83 -14 -94 -35 -19 -60 4
-60 54 0 23 3 46 7 49 13 13 61 7 67 -9z"/>
<path d="M4242 2283 c4 -101 26 -113 30 -16 l3 68 30 0 30 0 3 -68 c4 -89 25
-86 32 4 5 58 7 64 29 67 32 5 41 -12 41 -81 0 -42 3 -58 13 -55 15 6 21 95 8
130 -9 25 -11 25 -116 26 l-106 1 3 -76z"/>
<path d="M4510 2279 c0 -96 18 -106 22 -11 l3 67 32 3 32 3 3 -68 c4 -85 21
-85 28 -1 5 62 5 63 35 63 l30 0 3 -67 c4 -95 31 -89 30 6 0 81 -4 83 -117 84
l-101 1 0 -80z"/>
<path d="M4770 2291 c0 -80 5 -86 72 -86 l48 0 0 78 c0 61 -3 77 -15 77 -10 0
-15 -16 -17 -62 -3 -63 -3 -63 -33 -63 -30 0 -30 0 -33 63 -4 85 -22 79 -22
-7z"/>
<path d="M4920 2278 c0 -59 3 -79 13 -76 7 3 14 30 17 69 5 58 7 64 29 67 32
5 41 -12 41 -80 0 -34 4 -58 10 -58 7 0 10 29 8 78 l-3 77 -57 1 -58 1 0 -79z"/>
<path d="M5092 2283 c2 -44 7 -78 13 -78 6 0 11 34 13 78 2 65 0 77 -13 77
-13 0 -15 -12 -13 -77z"/>
<path d="M5176 2338 c-42 -60 0 -147 63 -133 17 4 36 16 42 27 16 29 4 33 -27
9 -44 -35 -80 3 -64 69 7 30 56 42 66 16 6 -15 34 -23 34 -10 0 46 -86 63
-114 22z"/>
<path d="M5330 2345 c-17 -21 -4 -32 20 -17 31 19 60 15 60 -8 0 -15 -7 -20
-26 -20 -41 0 -64 -18 -64 -50 0 -36 13 -44 72 -45 l48 0 0 66 c0 37 -5 70
-12 77 -17 17 -83 15 -98 -3z m78 -87 c-3 -24 -42 -38 -59 -21 -18 18 2 43 33
43 25 0 29 -4 26 -22z"/>
<path d="M5570 2280 c0 -47 4 -80 10 -80 6 0 10 33 10 80 0 47 -4 80 -10 80
-6 0 -10 -33 -10 -80z"/>
<path d="M5656 2341 c-31 -34 -22 -106 15 -129 72 -45 145 71 83 132 -22 22
-77 20 -98 -3z m82 -13 c19 -19 14 -73 -7 -92 -39 -36 -87 26 -62 80 12 26 48
33 69 12z"/>
<path d="M5812 2282 c4 -93 21 -99 28 -10 5 57 7 63 29 66 32 5 41 -12 41 -80
0 -34 4 -58 10 -58 7 0 10 29 8 78 l-3 77 -58 2 -58 1 3 -76z"/>
<path d="M6065 2336 c-24 -37 -16 -95 17 -119 31 -23 72 -17 89 14 15 30 2 36
-21 11 -24 -26 -30 -27 -54 -9 -18 13 -25 74 -11 96 10 16 46 13 67 -6 23 -20
33 -12 18 16 -16 32 -84 29 -105 -3z"/>
<path d="M6216 2338 c-52 -74 29 -172 97 -118 32 25 37 81 11 118 -21 31 -87
31 -108 0z m94 -24 c16 -42 -8 -94 -44 -94 -15 0 -36 35 -36 60 0 46 10 60 41
60 22 0 32 -6 39 -26z"/>
<path d="M6380 2278 c0 -45 4 -78 10 -78 6 0 10 24 10 54 0 72 6 86 41 86 28
0 28 -1 31 -67 2 -42 8 -68 16 -71 9 -3 12 13 12 55 0 69 9 86 41 81 22 -3 24
-8 27 -71 4 -85 26 -88 30 -4 5 91 0 95 -118 95 l-100 -1 0 -79z"/>
<path d="M6640 2255 c0 -63 4 -105 10 -105 6 0 10 14 10 31 0 28 2 30 28 24
63 -13 102 59 70 130 -10 22 -17 25 -65 25 l-53 0 0 -105z m94 69 c12 -32 6
-83 -11 -92 -30 -17 -63 6 -63 44 0 49 9 64 40 64 16 0 30 -7 34 -16z"/>
<path d="M6820 2345 c-16 -20 -5 -32 14 -16 20 17 60 10 64 -11 3 -14 -3 -18
-25 -18 -36 0 -73 -25 -73 -49 0 -29 28 -45 81 -46 l49 -1 0 52 c0 83 -12 104
-59 104 -22 0 -44 -6 -51 -15z m78 -87 c-3 -25 -43 -37 -60 -20 -19 19 0 42
32 42 27 0 31 -3 28 -22z"/>
<path d="M6962 2283 c4 -101 26 -113 30 -16 l3 68 30 0 30 0 3 -68 c4 -85 26
-88 30 -4 4 83 -7 97 -74 97 l-55 0 3 -77z"/>
<path d="M7122 2348 c3 -7 15 -44 28 -82 l23 -70 -27 -22 c-28 -24 -19 -42 12
-25 17 9 59 108 78 185 12 50 -19 25 -34 -27 -7 -26 -17 -45 -21 -42 -5 2 -14
25 -21 50 -8 27 -18 45 -27 45 -8 0 -13 -6 -11 -12z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
